$(function(){

  // drawer menu
  var target = $('body');
  $('#js-drawer, #drawerOverray').click(function(){
    target.toggleClass('drawerOpen');
  });
  // when scroll
  $(window).bind("scroll", function() {
    if ($(this).scrollTop() > 1) {
      $('#js-drawer').addClass('drawerBtnCompact');
    } else {
      $('#js-drawer').removeClass('drawerBtnCompact');
    }
  });

  // smooth scroll
  $('a[href^="#"]').click(function(){
   var speed = 1000;
   var href= $(this).attr("href");
   var target = $(href == "#" || href == "" ? 'html' : href);
   var position = target.offset().top;
   $("html, body").animate({scrollTop:position}, speed, "swing");
   return false;
  });

  // slide main visual
  if(typeof(siteurl) != 'undefined') {
   var speed = 1000;
   var times = 5000;
   var bgimages = [
    siteurl + '/img/mv_01.jpg',
    siteurl + '/img/mv_02.jpg',
    siteurl + '/img/mv_03.jpg',
    siteurl + '/img/mv_04.jpg'
   ];
   $(function(){
    var thisnum = 0;
    setInterval(function(){
     $(".js-bg-slider").fadeOut(speed,function(){
      thisnum = thisnum === bgimages.length - 1 ? 0 : thisnum + 1;
      $(this).css({"background-image":"url("+bgimages[thisnum]+")"}).fadeIn(speed);
     });
    }, times);
   });
  }
});

document.addEventListener('DOMContentLoaded', function() {
  var targets = document.getElementsByClassName('validation-number');
  var submitelement = document.getElementsByClassName('submit');
  for (var i=0 ; i<targets.length ; i++) {
    targets[i].oninput = function () {
      var alertelement = this.parentNode.getElementsByClassName('alertarea');
      if( ( this.value != '') && ( this.value.match( /[^\d\-]+/ )) ) {
        if( alertelement[0] ) { alertelement[0].innerHTML = '※ 電話番号には数字と「-（ハイフン記号）」だけが使えます'; }
        this.style.border = "2px solid #E80B6F";
        submitelement[0].setAttribute('disabled','');
      } else {
        if( alertelement[0] ) { alertelement[0].innerHTML = ""; }
        this.style.border = "1px solid #ccc";
        submitelement[0].removeAttribute('disabled');
      }
    }
  }
});
